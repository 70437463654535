<template>
  <div class="reservation-section">
    <v-container>
      <v-lazy
        :options="{ threshold: 0.9 }"
        transition="slide-y-reverse-transition"
        :min-height="50"
      >
        <h1
          class="text-mat-on-surface mb-3"
          :class="{ 'text-h3': this.$vuetify.display.mdAndUp }"
        >
          {{ $t("reservation.headline") }}
        </h1>
      </v-lazy>
      <v-lazy
        :options="{ threshold: 0.9 }"
        transition="slide-y-reverse-transition"
        :min-height="100"
      >
        <p class="text-mat-on-surface-variant" :class="{ 'w-75 mx-auto': this.$vuetify.display.smAndUp }">
          {{ $t("reservation.content") }}
        </p>
      </v-lazy>
    </v-container>
    <div
      class="our-fleet"
      :class="{
        'd-flex justify-center flex-wrap': this.$vuetify.display.mdAndUp,
      }"
    >
      <div v-for="img in images" class="ma-2">
        <v-img
          :width="this.$vuetify.display.mdAndUp ? '350' : '320'"
          :aspect-ratio="3 / 4"
          cover
          :src="require('@/assets/cars-min/' + img)"
          style="background-color: #212121"
        ></v-img>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReservationSection",
  data: () => ({
    images: [
      "doberman_20231124_0.jpg",
      "doberman_20231124_1.jpg",
      "doberman_20231124_6.jpg",
      "doberman_20231124_2.jpg",
      "doberman_20231124_3.jpg",
      "doberman_20231124_4.jpg",
    ],
  }),
};
</script>

<style scoped>
.our-fleet {
  overflow: auto;
  white-space: nowrap;
}

.our-fleet div {
  display: inline-block;
}
</style>
