<template>
  <v-app>
    <NavigationDrawer class="text-center"/>

    <v-main class="pt-0">
      <router-view />
    </v-main>

    <v-footer>
      <div class="text-center w-100 text-caption">
        &copy; {{ new Date().getFullYear() }} — DoberMan Rental
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import GradientBackground from "./components/GradientBackground.vue";
import NavigationDrawer from "./components/NavigationDrawer.vue";

export default {
  data: () => ({
    drawer: false,
    content: {
      title: "DoberManGarage",
      subtitle: "Auta na wynajem xDDD",
    },
  }),
  components: { GradientBackground, NavigationDrawer },
  watch: {
    drawer: function () {
      if (this.drawer) {
        document.documentElement.style.overflow = "hidden";
        return;
      }
      document.documentElement.style.overflow = "auto";
    },
    $route(to) {
      document.title = to.meta.title;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,300;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");

#app {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.v-container {
  max-width: 1280px !important;
}

.no-bullets {
  list-style-type: none;
}

.btn-gradient {
	 position: relative;
	 box-sizing: border-box;
	 background-clip: padding-box;
	/* !importanté */
	 border: solid 2px transparent;
	/* !importanté */
	 border-radius: 10px;
}

.btn-gradient:before {
	 content: '';
	 position: absolute;
	 top: 0;
	 right: 0;
	 bottom: 0;
	 left: 0;
	 margin: -2px; /* !importanté */
	 border-radius: inherit; /* !importanté */
	 background: linear-gradient(40deg, #14B8A6, #a855f7, #E6F14A);
}
</style>
