import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import i18n from './i18n'
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n'
import { useI18n } from 'vue-i18n'

// Font Awesome
import '@fortawesome/fontawesome-free/css/all.css' // Ensure your project is capable of handling css files

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, fa } from 'vuetify/iconsets/fa'

// Calendar
import { setupCalendar } from 'v-calendar';

const myCustomDarkTheme = {
  dark: true,
  colors: {
    "primary": "#ddb7ff",
    "on-primary": "#490080",
    "primary-container": "#6900b3",
    "on-primary-container": "#f0dbff",
    "secondary": "#c5cf27",
    "on-secondary": "#303300",
    "secondary-container": "#464a00",
    "on-secondary-container": "#e1ec45",
    "tertiary": "#4fdbc8",
    "on-tertiary": "#003731",
    "tertiary-container": "#005048",
    "on-tertiary-container": "#71f8e4",
    "error": "#ffb4ab",
    "error-container": "#93000a",
    "on-error": "#690005",
    "on-error-container": "#ffdad6",
    "background": "#1a1c1e",
    "on-background": "#e7e1e5", //#e7e1e5
    "surface": "#212121",
    "on-surface": "#F6F4F5",
    "mat-on-surface": "#F6F4F5",
    "surface-variant": "#4a454e",
    "on-surface-variant": "#ccc4ce",
    "mat-on-surface-variant": "#C2C7CE",
    "outline": "#968e98",
    "inverse-on-surface": "#1d1b1e",
    "inverse-surface": "#e7e1e5",
    "inverse-primary": "#842bd2",
    "shadow": "#000000",
    "surface-tint": "#ddb7ff",
    "outline-variant": "#4a454e",
    "scrim": "#000000"
  }
}

const vuetify = createVuetify({
  components,
  directives,
  locale: {
    adapter: createVueI18nAdapter({ i18n, useI18n }),
  },
  theme: {
    defaultTheme: 'myCustomDarkTheme',
    themes: {
      myCustomDarkTheme
    },
  },
  icons: {
    defaultSet: 'fa',
    aliases,
    sets: {
      fa,
    },
  }
})

createApp(App).use(vuetify).use(setupCalendar).use(router).use(i18n).mount('#app')
