<template>
  <div class="blured-dot"></div>
</template>

<script>
export default {
  name: "BluredDot",
  data: () => ({
    //json data here
  }),
};
</script>

<style scoped>
.blured-dot {
  position: relative;
  left: 70vw;
  top: 100px;
  width: 25vw;
  height: 300px;
  margin-bottom: -300px;
  border: 6px solid #212121;
  border-radius: 50%;
  background: #7986CB;
  background-blend-mode: normal;
  box-shadow: 50px 100px 50px -18px #1B5E20;
  filter: blur(200px) brightness(50%);
}

@media only screen and (max-width: 600px) {
  .blured-dot {
    filter: blur(150px);
  }
}
</style>
