<template>
  <div class="navigation-drawer">
    <v-navigation-drawer
      v-model="drawer"
      temporary
      location="right"
      elevation="0"
      width="10000"
      color="background"
      border="0"
    >
      <GradientBackground />
      <div class="d-flex justify-space-between align-center" style="height: 64px"> <!-- justify-space-between -->
        <LocaleSwitcher class="ml-2"/>
        <v-app-bar-nav-icon @click="drawer = !drawer" class="mr-2" aria-label="Close Menu">
          <v-icon icon="fas fa-xmark"></v-icon>
        </v-app-bar-nav-icon>
      </div>

      <div class="d-flex flex-column justify-center align-center mt-8">
        <div class="w-75 mb-1">
          <v-list-item
            v-for="item in nav.page"
            variant="plain"
            rounded="pill"
            class="ma-8"
            :to="item.link"
            :title="$t(`nav.${item.caption}`)"
          ></v-list-item>
        </div>
        <div class="w-75"><v-divider></v-divider></div>
        <div class="w-100 my-6">
          <v-btn
            v-for="item in nav.social"
            :key="item"
            class="mx-4"
            :icon="item.icon"
            variant="plain"
            :href="item.link"
            target="_blank"
            :aria-label="item.ariaLabel"
            @click="sendEvent(item.ariaLabel)"
          ></v-btn>
        </div>
        <div class="w-75"><v-divider></v-divider></div>
        <v-list-item variant="plain" rounded="pill" class="ma-8">
          <small>contact@doberman-rental.nl</small>
        </v-list-item>
      </div>
    </v-navigation-drawer>

    <v-app-bar flat color="transparent">
      <v-app-bar-title align="left"><router-link to="/" style="text-decoration: none; color: inherit;">{{ content.title }}</router-link></v-app-bar-title>
      <v-app-bar-nav-icon @click="drawer = !drawer" aria-label="Menu">
        <v-icon icon="fas fa-bars"></v-icon>
      </v-app-bar-nav-icon>
    </v-app-bar>
  </div>
</template>

<script>
import GradientBackground from "@/components/GradientBackground.vue";
import LocaleSwitcher from "@/components/LocaleSwitcher";

export default {
  name: "NavigationDrawer",
  data: () => ({
    drawer: false,
    content: {
      title: "DoberMan Rental",
      subtitle: "Auta na wynajem xD",
    },
    nav: {
      page: [
        { link: "/", caption: "home" },
        { link: "/contact", caption: "contact" },
        { link: "/fleet", caption: "gallery" },
        { link: "/terms-and-conditions", caption: "terms" },
      ],
      social: [
        { link: "mailto:contact@doberman-rental.nl", icon: "fa-regular fa-envelope", ariaLabel: "Email" },
        { link: "https://wa.me/31633368611", icon: "fa-brands fa-whatsapp", ariaLabel: "WhatsApp" },
        { link: "https://tiktok.com/@dobermanrental", icon: "fa-brands fa-tiktok", ariaLabel: "TikTok" },
        { link: "https://instagram.com/doberman_rental", icon: "fa-brands fa-instagram", ariaLabel: "Instagram" }
      ],
    }
  }),
  components: { GradientBackground, LocaleSwitcher },
  methods: {
    sendEvent(social) {
      //console.log("Siema: " + social);
      gtag('event', social + '_redirect');
    }
  },
  /*created() {
    // Generalnie to chcesz podmienić wartość w data, ale to jest szybki workaround xD
    this.nav.page[0].caption = this.$t('nav.home');
    this.nav.page[1].caption = this.$t('nav.contact');
    this.nav.page[2].caption = this.$t('nav.gallery');
    this.nav.page[3].caption = this.$t('nav.terms');
  },*/
  watch: {
    drawer: function () {
      if (this.drawer) {
        document.documentElement.style.overflow = "hidden";
        return;
      }
      document.documentElement.style.overflow = "auto";
    },
    $route(to) {
      document.title = to.meta.title;
    },
  },
};
</script>
