<template>
  <div class="about-section">
    <v-container class="mt-16">
      <div
        class="d-flex align-center"
        :class="{ 'flex-column': this.$vuetify.display.xs }, { 'w-75 mx-auto': this.$vuetify.display.mdAndUp }"
      >
        <v-lazy
          :options="{ threshold: 0.5 }"
          transition="slide-y-reverse-transition"
          :min-height="220"
          class="text-left"
        >
          <div>
            <h1
              class="mb-3 text-mat-on-surface"
              :class="{ 'text-h3': this.$vuetify.display.mdAndUp }"
            >
              {{ $t("aboutUs.headline") }}
            </h1>
            <p class="text-mat-on-surface-variant">
              {{ $t("aboutUs.content") }}
            </p>
          </div>
        </v-lazy>
        <v-lazy
          :min-height="300"
          :options="{ threshold: 0.8 }"
          transition="slide-y-reverse-transition"
          class="order-sm-first mt-6"
        >
          <v-img
            width="400"
            max-width="90vw"
            :src="require('@/assets/doberman_logo_current_xd.png')"
            alt="Logo"
          ></v-img>
        </v-lazy>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "AboutSection",
  data: () => ({
    // data here
  }),
};
</script>

<style scoped></style>
