<template>
  <div class="social-media-component">
    <v-container class="my-16">
      <v-lazy
        :options="{ threshold: 0.9 }"
        transition="slide-y-reverse-transition"
        :min-height="50"
      >
        <h1
          class="text-mat-on-surface mb-3"
          :class="{ 'text-h3': this.$vuetify.display.mdAndUp }"
        >
          {{ $t("socialMedia.headline") }}
        </h1>
      </v-lazy>
      <v-lazy
        :options="{ threshold: 0.9 }"
        transition="slide-y-reverse-transition"
        :min-height="80"
      >
        <p class="text-mat-on-surface-variant" :class="{ 'w-75 mx-auto': this.$vuetify.display.smAndUp }">
          {{ $t("socialMedia.content") }}
        </p>
      </v-lazy>
      <v-lazy
        :options="{ threshold: 0.9 }"
        transition="slide-y-reverse-transition"
        :min-height="60"
      >
        <div class="w-100 my-4">
          <v-btn
            v-for="item in socials"
            :key="item"
            class="mx-4"
            :icon="item.icon"
            variant="plain"
            size="x-large"
            :href="item.link"
            target="_blank"
            :aria-label="item.ariaLabel"
            @click="sendEvent(item.ariaLabel)"
          ></v-btn>
        </div>
      </v-lazy>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "SocialMediaSection",
  data: () => ({
    socials: [
      { link: "https://tiktok.com/@dobermanrental", icon: "fa-brands fa-tiktok", ariaLabel: "TikTok" },
      { link: "https://instagram.com/doberman_rental", icon: "fa-brands fa-instagram", ariaLabel: "Instagram" }
    ],
  }),
  methods: {
    sendEvent(social) {
      //console.log("Siema: " + social);
      gtag('event', social + '_redirect');
    }
  },
};
</script>
