<template>
  <div class="home text-center">
    <HeroCarousel />
    <AboutSection />
    <BluredDot />
    <FleetSection :class="{ 'pb-16': this.$vuetify.display.mdAndUp }"/>
    <BluredDot2 />
    <WhyUsSection :class="{ 'pb-16': this.$vuetify.display.mdAndUp }"/>
    <ReservationSection :class="{ 'pb-16': this.$vuetify.display.mdAndUp }"/>
    <BluredDot />
    <LocationsSection :class="{ 'pb-16': this.$vuetify.display.mdAndUp }"/>
    <TestimonialsSection :class="{ 'pb-16': this.$vuetify.display.mdAndUp }"/>
    <BluredDot2 />
    <SocialMediaSection :class="{ 'pb-16': this.$vuetify.display.mdAndUp }"/>
  </div>
</template>

<script>
// @ is an alias to /src
import HeroCarousel from "@/components/HeroCarousel.vue";
import AboutSection from "@/components/AboutSection.vue";
import FleetSection from "@/components/FleetSection.vue";
import WhyUsSection from "@/components/WhyUsSection.vue";
import ReservationSection from "@/components/ReservationSection.vue";
import LocationsSection from "@/components/LocationsSection.vue";
import TestimonialsSection from "@/components/TestimonialsSection.vue";
import SocialMediaSection from "@/components/SocialMediaSection.vue";
import BluredDot from "@/components/dots/BluredDot.vue";
import BluredDot2 from "@/components/dots/BluredDot2.vue";

export default {
  name: "HomeView",
  data: () => ({
    // xD
  }),
  components: {
    HeroCarousel,
    AboutSection,
    FleetSection,
    WhyUsSection,
    ReservationSection,
    LocationsSection,
    TestimonialsSection,
    SocialMediaSection,
    BluredDot,
    BluredDot2,
  },
  mounted() {
    //console.log(this.$vuetify.display);
  },
};
</script>

<style>

</style>
