<template>
  <div class="locale-switcher d-flex align-center">
    <!--<select v-model="$i18n.locale" @change="saveLocale($i18n.locale)">
      <option :value="locale.code" v-for="locale in locales" :key="locale.code">
        {{locale.name}}
      </option>
    </select>-->
    <v-select
      @update:menu="saveLocale($i18n.locale)"
      v-model="$i18n.locale"
      :items="locales"
      item-title="name"
      item-value="code"
      label="Language"
      single-line
      density="compact"
      variant="outlined"
      hide-details
    ></v-select>
  </div>
</template>

<script>
import { getSupportedLocales } from "@/i18n/annotated-locales"
export default {
  data: () => ({ locales: getSupportedLocales() }),
  methods: {
    saveLocale(languageCode) {
      localStorage.setItem("locale", languageCode);
      //console.log("Selected language code: " + languageCode);
    }
  }
}
</script>
