import { createI18n } from 'vue-i18n'
import getBrowserLocale from "@/i18n/get-browser-locale"
import { supportedLocalesInclude } from "@/i18n/annotated-locales"
//import en from '@/locales/en.json';
//import pl from '@/locales/pl.json';
//import nl from '@/locales/nl.json';

// Pobieranie dostępnych plików tłumaczeń z folderu /locales
// https://medium.com/i18n-and-l10n-resources-for-developers/the-ultimate-guide-to-vue-localization-with-vue-i18n-bf98b1d40c65#26b5
function loadLocaleMessages() {
  const locales = require.context(
    "@/locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

function getStartingLocale() {
  const browserLocale = getBrowserLocale({ countryCodeOnly: true })

  if (supportedLocalesInclude(browserLocale)) {
    return browserLocale
  } else {
    return "en"
  }
}

function getSavedLocale() {
  return localStorage.getItem("locale")
}

const i18n = createI18n({
  legacy: false,
  locale: getSavedLocale() || getStartingLocale(),
  fallbackLocale: 'en',
  messages: loadLocaleMessages(),
})

export default i18n
