import { createRouter, createWebHistory } from 'vue-router'
import DefaultHomeView from '../views/DefaultHomeView.vue'
import HomeView from '../views/HomeView.vue'
import { t } from 'vue-i18n'

const routes = [
  {
    path: '/default-home',
    name: 'default-home',
    component: DefaultHomeView
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: "DoberMan - Home"
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '@/views/ContactView.vue'),
    meta: {
      title: "DoberMan - Contact"
    }
  },
  {
    path: '/fleet',
    name: 'fleet',
    component: () => import(/* webpackChunkName: "about" */ '@/views/FleetView.vue'),
    meta: {
      title: "DoberMan - Fleet"
    }
  },
  {
    path: '/terms-and-conditions',
    name: 'terms-and-conditions',
    component: () => import(/* webpackChunkName: "about" */ '@/views/TermsView.vue'),
    meta: {
      title: "DoberMan - Terms and Conditions"
    }
  },
  {
    path: '/car/:id',
    name: 'car',
    component: () => import(/* webpackChunkName: "about" */ '@/views/car/CarView.vue'),
    meta: {
      title: "DoberMan - Rent a Car"
    }
  },
  {
    path: '/rent/:id',
    name: 'rent',
    component: () => import(/* webpackChunkName: "about" */ '@/views/car/RentView.vue'),
    meta: {
      title: "DoberMan - Rent a Car"
    }
  },
  {
    path: '/admin',
    name: 'admin-panel',
    component: () => import(/* webpackChunkName: "about" */ '@/views/admin/PanelView.vue'),
    meta: {
      title: "DoberMan - Admin"
    }
  },
  {
    path: '/admin/car/edit/:id',
    name: 'car-edit',
    component: () => import(/* webpackChunkName: "about" */ '@/views/admin/CarEditView.vue'),
    meta: {
      title: "DoberMan - Admin"
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'page-not-found',
    component: () => import(/* webpackChunkName: "about" */ '@/views/PageNotFoundView.vue'),
    meta: {
      title: "DoberMan - 404"
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0, behavior: 'smooth' }
    }
  }
})

export default router
