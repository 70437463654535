<template>
  <div class="locations-component">
    <v-container class="my-16">
      <div
        class="d-flex align-center"
        :class="{ 'flex-column': this.$vuetify.display.smAndDown, 'w-75 mx-auto': this.$vuetify.display.lgAndUp }"
      >
        <div
          class="text-left"
          :class="{ 'over-image-left': this.$vuetify.display.mdAndUp }"
        >
          <v-lazy
            :options="{ threshold: 0.9 }"
            transition="slide-y-reverse-transition"
            :min-height="200"
          >
            <div>
              <h1
                class="mb-3 text-mat-on-surface"
                :class="{ 'text-h3': this.$vuetify.display.mdAndUp }"
              >
                {{ $t("locations.headline") }}
              </h1>
              <p
                class="text-mat-on-surface-variant"
                :class="{ 'text-shaddow': this.$vuetify.display.mdAndUp }"
              >
                {{ $t("locations.content") }}
              </p>
            </div>
          </v-lazy>
        </div>
        <v-img
          height="300"
          width="400"
          cover
          :src="require('@/assets/cars-min/DSC03434.jpg')"
          alt="Car1"
          class="order-md-first mt-6"
          style="filter: brightness(80%);"
        ></v-img>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "LocationsSection",
  data: () => ({
    // data here
  }),
};
</script>

<style scoped>
.over-image-left {
  margin-left: -50px;
  z-index: 100;
}
.text-shaddow {
  text-shadow: 0 0 5px black;
}
</style>