<template>
  <div class="testimonials-component">
    <v-container>
      <v-lazy
        :options="{ threshold: 0.9 }"
        transition="slide-y-reverse-transition"
        :min-height="50"
      >
        <h1
          class="text-mat-on-surface mb-3"
          :class="{ 'text-h3': this.$vuetify.display.mdAndUp }"
        >
          {{ $t("testimonials.headline") }}
        </h1>
      </v-lazy>
      <div class="d-flex justify-center flex-wrap">
        <div v-for="(item, i) in 3">
          <v-lazy
            :options="{ threshold: 0.9 }"
            transition="slide-y-reverse-transition"
            :min-height="130"
          >
            <v-card
              max-width="350"
              min-width="250"
              class="text-left rounded-0 my-4 mx-2 mb-0"
              variant="outlined"
            >
              <v-card-item>
                <v-card-title>{{ $t(`testimonials.content[${i}].author`) }}</v-card-title>

                <v-card-subtitle>
                  <v-icon
                    color="#FFD700"
                    icon="fa-solid fa-star"
                    size="x-small"
                    class="mr-2"
                    v-for="n in 5"
                  ></v-icon>
                </v-card-subtitle>
              </v-card-item>

              <v-card-text> {{ $t(`testimonials.content[${i}].comment`) }} </v-card-text>
            </v-card>
          </v-lazy>
        </div>
        
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "TestimonialsSection",
  data: () => ({
    // data here
  }),
};
</script>
