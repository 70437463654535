<template>
  <div class="fleet-section">
    <v-container
      class="mt-16"
      :class="{ 'w-75 mx-auto': this.$vuetify.display.mdAndUp }"
    >
      <v-lazy
        :options="{ threshold: 0.9 }"
        transition="slide-y-reverse-transition"
        :min-height="50"
      >
        <h1
          class="mb-3 text-mat-on-surface"
          :class="{ 'text-h3': this.$vuetify.display.mdAndUp }"
        >
          {{ $t("ourFleet.headline") }}
        </h1>
      </v-lazy>
      <v-lazy
        :options="{ threshold: 0.9 }"
        transition="slide-y-reverse-transition"
        :min-height="90"
      >
        <p
          class="text-mat-on-surface-variant"
          :class="{ 'w-75 mx-auto': this.$vuetify.display.smAndUp }"
        >
          {{ $t("ourFleet.content") }}
        </p>
      </v-lazy>
    </v-container>
    <div
      class="our-fleet"
      :class="{
        'd-flex justify-center flex-wrap': this.$vuetify.display.mdAndUp,
      }"
    >
      <div v-for="img in images" :key="img" class="ma-2">
        <v-img
          :width="this.$vuetify.display.mdAndUp ? '350' : '320'"
          :aspect-ratio="3 / 4"
          cover
          :src="require('@/assets/cars-min/' + img)"
          style="background-color: #212121"
        ></v-img>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FleetSection",
  data: () => ({
    images: [
      "DSC03521.jpg",
      "DSC03503.jpg",
      "DSC03570.jpg",
      "DSC03512.jpg",
      "DSC03584.jpg",
      "DSC03530.jpg",
      "DSC03508.jpg",
      "DSC03615.jpg",
    ],
  }),
};
</script>

<style scoped>
.our-fleet {
  overflow: auto;
  white-space: nowrap;
}

.our-fleet div {
  display: inline-block;
}
</style>
