<template>
  <div class="why-us-section">
    <v-container
      class="my-16"
      :class="{ 'w-75 mx-auto': this.$vuetify.display.mdAndUp }"
    >
      <v-lazy
        :options="{ threshold: 0.9 }"
        transition="slide-y-reverse-transition"
        :min-height="50"
      >
        <h1
          class="text-mat-on-surface"
          :class="{ 'text-h3': this.$vuetify.display.mdAndUp }"
        >
          {{ $t("whyUs.headline") }}
        </h1>
      </v-lazy>
      <ul class="d-flex justify-space-evenly flex-wrap">
        <li
          v-for="(item, index) in 3"
          class="text-mat-on-surface-variant no-bullets ma-10 mb-0"
          style="width: 200px"
        >
          <v-lazy
            :options="{ threshold: 0.9 }"
            transition="slide-y-reverse-transition"
            :min-height="176"
          >
            <div>
              <v-icon size="x-large" :icon='$t("whyUs.content["+index+"].icon")'></v-icon>
              <div class="mt-6">
                {{ $t("whyUs.content["+index+"].text") }}
              </div>
            </div>
          </v-lazy>
        </li>
      </ul>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "WhyUsSection",
  data: () => ({
    // data here
  }),
};
</script>
