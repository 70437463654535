<template>
  <div class="hero-carousel">
    <v-carousel
      :show-arrows="false"
      cycle
      interval="8000"
      hide-delimiter-background
      height="100vh"
      vertical-delimiters="left"
      delimiter-icon="fa-solid fa-caret-right"
      class="darker"
    >
      <v-carousel-item
        :src="require('@/assets/cars-min/DSC03428.jpg')"
        :lazy-src="require('@/assets/cars-lazy/DSC03428.jpg')"
        alt="Hero Image"
        cover
        transition="fade-transition"
        reverse-transition="fade-transition"
      ></v-carousel-item>

      <v-carousel-item
        :src="require('@/assets/cars-min/DSC03668.jpg')"
        :lazy-src="require('@/assets/cars-lazy/DSC03668.jpg')"
        alt="Hero Image"
        cover
        transition="fade-transition"
        reverse-transition="fade-transition"
      ></v-carousel-item>

      <v-carousel-item
        :src="require('@/assets/cars-min/DSC03626.jpg')"
        :lazy-src="require('@/assets/cars-lazy/DSC03626.jpg')"
        alt="Hero Image"
        cover
        transition="fade-transition"
        reverse-transition="fade-transition"
      ></v-carousel-item>
    </v-carousel>

    <div class="hero text-left text-mat-on-background">
      <p
        :class="{
          'text-subtitle-2': this.$vuetify.display.smAndDown,
          'text-subtitle-1': this.$vuetify.display.mdAndUp,
        }"
      >
        {{ $t("hero.subtitle") }}
      </p>
      <h1
        style="white-space: pre-line"
        :class="{
          'text-h4': this.$vuetify.display.smAndDown,
          'text-h2': this.$vuetify.display.mdAndUp,
        }"
      >
        {{ $t("hero.header") }}
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data: () => ({
    // data here
  }),
};
</script>

<style scoped>
.v-carousel .v-window-item {
  position: absolute;
  top: 0;
  width: 100%;
}
.fade-transition {
  transition: all 2s ease;
}

.darker {
  filter: brightness(80%);
}

.hero {
  position: absolute;
  top: 43vh;
  left: 14vw;
}
</style>