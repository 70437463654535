<template>
  <div class="blured-dot2"></div>
</template>

<script>
export default {
  name: "BluredDot2",
  data: () => ({
    //json data here
  }),
};
</script>

<style scoped>
.blured-dot2 {
  position: relative;
  left: -10vw;
  top: 300px;
  width: 300px;
  height: 100px;
  margin-bottom: -100px;
  margin-right: -300px;
  border: 6px solid #212121;
  border-radius: 50%;
  background: #795548;
  background-blend-mode: normal;
  box-shadow: 50px 100px 50px -18px #3f51b5;
  filter: blur(150px);
}
</style>
